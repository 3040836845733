import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';


const ReadArticlePage = () => {
  const [articleData, setArticleData] = useState(null);
  const [articleIsLoading, setArticleIsLoading] = useState(false);
  const { blogId, articleCategory, title } = useParams();

  useEffect(() => {
    if (blogId) {
      setArticleIsLoading(true);
      fetch(`https://cgjobschool-apis-staging.onrender.com/admin/chronicle/article/${blogId}`)
        .then((res) => res.json())
        .then((result) => {
          setArticleData(result);
          setArticleIsLoading(false);
        })
        .catch(() => {
          alert('something went wrong');
          setArticleIsLoading(false);
        });
    }
  }, []);

  if (articleIsLoading) return <div>Loading...</div>;

  if (!blogId) return <p>Broken Link</p>;
  if (blogId && !articleIsLoading && !articleData?._id)
    return <p> Data not found </p>;

  return (
    <>
      <div className="px-60 py-4 ">
        <div className="my-4">
          <h2 className="text-4xl font-semibold">{articleData?.title}</h2>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: articleData?.content ?? '',
          }}
        />
      </div>
    </>
  );
};

export default ReadArticlePage;
