import { FC, memo, useEffect, useState } from 'react';



const ArticlesSlider = () => {
 
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
  const [pagination, setPagination] = useState({articleCategory:'', search:''})
 
  useEffect(() => {
    setLoading(true);
    fetch(`https://cgjobschool-apis-staging.onrender.com/admin/chronicle/articles`,{
        method:"GET",
    })
    .then(res=>res.json())
      .then((res) => {
       
        if (res?.data) {
          setArticles(res?.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [pagination]);

  if(loading) return <div>Loading...</div>
  return (
    <div>
      
      <div className="flex gap-4 overflow-x-auto scroll-smooth scrollbar-hide">
        { articles.length &&
          articles.map((article)=>{
            return(
                  <div key={article._id} className="min-w-[300px] p-4 rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <p className='text-xl font-bold' >{article.title}</p>
                    <p className='text-xs' >{article.description}</p>
                    <a className='text-blue-600'  href={`/${article.articleCategory}/${article.title.split(' ').join('_')}/${article._id}`} target='_blank' >Read More</a>  
                  </div>
                  )
          })
        }
      </div>
    </div>
  );
};

export default memo(ArticlesSlider);
