import React from "react";
import HomePage from "./pages/HomePage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccountDeletionRequest from "./pages/AccountDelationRequest";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Navbar from "./components/Navbar";
import ReadArticlePage from "./pages/ReadArticlePage";

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <div className="pt-16">
          <Routes>
            <Route path="/" Component={HomePage} />
            <Route
              path="/account-deletion-request"
              Component={AccountDeletionRequest}
            />
            <Route path="/privacy-policy" Component={PrivacyPolicy} />
            <Route
              path="/:articleCategory/:title/:blogId"
              Component={ReadArticlePage}
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
